import React from 'react'
import { render } from 'react-dom'
import { Switch, Route, BrowserRouter } from 'react-router-dom'

import ThemeProvider from 'styles/ThemeProvider'
import FlashMessageProvider from '../creators/common/flashMessages/FlashMessageProvider'
import FlashMessagesContainer from 'creators/common/flashMessages/FlashMessagesContainer'
import Root from './Root'
import styled from 'styled-components'
import DebugProvider from 'shared/debug/DebugProvider.debug'
import QuickConnectDevtool from 'shared/debug/QuickConnectDevtool.debug'
import FeatureFlagProvider from 'shared/FeatureFlagProvider'
import FacebookSdkProvider from '../creators/FacebookSdkProvider'

document.addEventListener('DOMContentLoaded', () => {
  render(
    <DebugProvider>
      <ThemeProvider>
        <FlashMessageProvider>
          <FacebookSdkProvider>
            <FeatureFlagProvider>
              <BrowserRouter>
                <FlashContainer>
                  <FlashMessagesContainer />
                </FlashContainer>
                <Switch>
                  <Route exact path="/creators/sign_up" component={Root} />
                  <Route exact path="/login" component={Root} />
                </Switch>
                <QuickConnectDevtool />
              </BrowserRouter>
            </FeatureFlagProvider>
          </FacebookSdkProvider>
        </FlashMessageProvider>
      </ThemeProvider>
    </DebugProvider>,
    document.getElementById('creators-registration-app')
  )
})

const FlashContainer = styled.div`
  left: 50%;
  position: fixed;
  transform: translateX(-50%);
`
