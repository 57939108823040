import React, { useState } from 'react'
import 'creators/common/application'
import { Form, FormGroup, ControlLabel, HelpBlock } from 'react-bootstrap'
import {
  useForm,
  FormContext,
  useFormContext,
  Controller,
} from 'react-hook-form'
import MaterialInput from 'shared/components/MaterialInput'
import { Button } from 'shared/components/UIkit/Buttons'
import { ContinueWithFacebook } from './FacebookButton'
import { useFacebookSdk } from 'shared/useFacebookSdk'

export default function SignInForm({ noTitle = false, availableCountries }) {
  const [submitting, setSubmitting] = useState(false)
  const [isFacebookSdkReady] = useFacebookSdk()

  const form = useForm({
    defaultValues: {
      sign_in: {
        email: '',
      },
    },
  })

  const { clearError } = form

  const handleSubmit = () => {
    setSubmitting(true)
  }

  const token = $('meta[name="csrf-token"]').attr('content')

  return (
    <React.Fragment>
      {!noTitle && (
        <h1 className="font-light text-center size-h1 uppercase px-10">
          {I18n.t('js.creators_login.form.title')}
        </h1>
      )}
      <h3 className="font-light text-center bottom-0 top-4 fs-16">
        {I18n.t('js.creators_login.form.subtitle')}
      </h3>
      {isFacebookSdkReady && (
        <ContinueWithFacebook
          availableCountries={availableCountries}
          onBusy={isBusy => setSubmitting(isBusy)}
        />
      )}
      <FormContext {...form}>
        <Form
          id="creators-signin-form"
          className="signin-form"
          onSubmit={handleSubmit}
          noValidate
          acceptCharset="UTF-8"
          action="/login"
          method="post"
        >
          <input type="hidden" name="authenticity_token" value={token} />
          <Group name="email">
            <Controller
              rules={{ required: true }}
              name="sign_in[email]"
              noValidate
              as={
                <MaterialInput
                  label={I18n.t('js.creators_login.form.email.label')}
                  type="email"
                />
              }
            />
          </Group>

          <Group name="password">
            <Controller
              as={
                <MaterialInput
                  label={I18n.t('js.creators_login.form.password.label')}
                  type="password"
                />
              }
              onChange={() => {
                clearError('password')
              }}
              rules={{ required: true }}
              name="sign_in[password]"
            />
            <HelpBlock>
              <a href="/passwords/new" className="s12">
                {I18n.t('js.creators_login.form.password.forgot')}
              </a>
            </HelpBlock>
          </Group>

          <Button
            block
            size="sm"
            type="submit"
            loading={submitting}
            disabled={submitting}
          >
            {I18n.t('js.creators_login.form.submit')}
          </Button>
        </Form>
      </FormContext>
    </React.Fragment>
  )
}

const Group = ({ name, label, children, ...props }) => {
  const { errors } = useFormContext()
  const error = errors[name]
  return (
    <FormGroup {...props} validationState={error ? 'error' : null}>
      {label && (
        <ControlLabel className="color-gray-text-light">{label}</ControlLabel>
      )}
      {children}
      {error ? <HelpBlock className="has-error" /> : undefined}
    </FormGroup>
  )
}
