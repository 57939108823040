import React from 'react'

import { imagePath } from 'shared/images'

export default function Header() {
  return (
    <div className="header">
      <a href="/" className="header-logo">
        <img
          src={imagePath('logo.svg')}
          style={{ maxWidth: '156px' }}
          height={32}
        />
      </a>
    </div>
  )
}
