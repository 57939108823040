import React, { useState, useEffect, useMemo } from 'react'
import 'creators/common/application'
import { hot } from 'react-hot-loader/root'
import sortBy from 'lodash/sortBy'
import { withRouter } from 'react-router'
import { compose } from 'react-apollo'
import { Tabs, Tab } from 'react-bootstrap'
import APP_ISO_CONF from 'shared/appIsoConf'

import { withoutToken as fetchAPI } from 'shared/fetchAPI'

import Header from './Header'
import SignInForm from './SignInForm'
import SignUpForm from './SignUpForm'
import useBreakpoint from 'shared/useBreakpoint'

function Root({ availableCountries, history, location }) {
  const [signIn, setSignIn] = useState(location.pathname === '/login')

  const linkEnv = process.env.RAILS_ENV
  const lang = window.navigator.language.split('-')[0]

  const redirectURL =
    APP_ISO_CONF.creator_webapp_url[linkEnv][lang] ||
    APP_ISO_CONF.creator_webapp_url[linkEnv].default

  const { search } = window.location

  const proDemoUrl = `${
    APP_ISO_CONF.client_demo_url[I18n.locale.split('-')[0]] ||
    APP_ISO_CONF.client_demo_url.default
  }?utm_source=creator_sign_up_form`

  useEffect(() => {
    if (location.pathname === '/creators/sign_up') {
      window.location.replace(`${redirectURL}${search}`)
    }
  }, [])

  const handleClick = () => {
    if (signIn) {
      window.location.replace(`${redirectURL}${search}`)
    } else {
      history.replace('/login')
    }
  }

  const backgroundImageNumber = useMemo(
    () => Math.floor(Math.random() * 4) + 1,
    []
  )

  const desktop = useBreakpoint('md')

  if (location.pathname === '/creators/sign_up') return null

  return (
    <>
      <Header />
      {desktop ? (
        <div style={{ overflowX: 'hidden' }}>
          <div className={`cont ${signIn ? 's--signin' : 's--signup'}`}>
            <div className="form sign-in pbottom-20 ptop-50 flex flex-column align-items-center">
              <SignInForm availableCountries={availableCountries} />
            </div>
            <div className="sub-cont">
              <div
                className={`img auth-bg${backgroundImageNumber}`}
                onClick={handleClick}
              >
                <div className="img__text m--up">
                  <h1 className="font-light size-h1 uppercase color-white">
                    {I18n.t('js.creators.signin_signup.new')}
                  </h1>
                </div>
                <div className="img__text m--in">
                  <h1 className="font-light size-h1 uppercase color-white">
                    {I18n.t('js.creators.signin_signup.already_in')}
                  </h1>
                </div>
                <div className="buttons-container">
                  <div className="btn btn-outlined img__btn bottom-20">
                    {signIn ? (
                      <span className="m--up">
                        {I18n.t('js.creators.signin_signup.signup')}
                      </span>
                    ) : (
                      <span className="m--in">
                        {I18n.t('js.creators.signin_signup.signin')}
                      </span>
                    )}
                  </div>
                  <a
                    href={proDemoUrl}
                    onClick={e => e.stopPropagation()}
                    className="img__btn text-center color-white bottom-30 bold"
                  >
                    {I18n.t('js.creators.signin_signup.join_as_brand')}
                  </a>
                </div>
              </div>
              <div className="form sign-up pbottom-20 ptop-50 flex flex-column align-items-center">
                <SignUpForm availableCountries={availableCountries} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-column align-items-center">
          <Tabs
            id="signin_signup"
            onSelect={handleClick}
            animation
            bsStyle="tabs"
            className="sign-in-sign-up-sm pbottom-40 nav-tabs-registrations"
            activeKey={signIn}
          >
            <Tab
              tabClassName="sm-tab"
              eventKey={true}
              title={
                <h1 className="font-light s14 uppercase bottom-0 text-center">
                  {I18n.t('js.creators_login.form.title')}
                </h1>
              }
            >
              <div className="flex flex-column align-items-center top-40">
                <SignInForm availableCountries={availableCountries} />
                <a href={proDemoUrl} className="text-center top-20 bold">
                  {I18n.t('js.creators.signin_signup.join_as_brand')}
                </a>
              </div>
            </Tab>
            <Tab
              tabClassName="sm-tab"
              eventKey={false}
              title={
                <h1 className="font-light s14 uppercase bottom-0 text-center">
                  {I18n.t('js.creators.signin_signup.new')}
                </h1>
              }
            >
              <div className="flex flex-column align-items-center top-40">
                <SignUpForm availableCountries={availableCountries} />
                <a href={proDemoUrl} className="text-center top-20 bold">
                  {I18n.t('js.creators.signin_signup.join_as_brand')}
                </a>
              </div>
            </Tab>
          </Tabs>
        </div>
      )}
    </>
  )
}

export function withAvailableCountries(WrappedComponent) {
  return function AvailableCountries(props) {
    const [availableCountries, setAvailableCountries] = useState(null)

    useEffect(() => {
      ;(async () => {
        const countries = await fetchAPI(
          `/api/v3/countries?locale=${I18n.locale}`
        )
        const sortedCountries = sortBy(countries, [c => !c.prioritized])
        setAvailableCountries(sortedCountries)
      })()
    }, [])

    if (!availableCountries) return null

    return (
      <WrappedComponent {...props} availableCountries={availableCountries} />
    )
  }
}

export default compose(hot, withAvailableCountries, withRouter)(Root)
